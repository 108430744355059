import { gql } from '@apollo/client/core';
import { IsB2BEnable, IsB2BLogged } from 'B2B/Utils/IsB2B';

// MYORDER - MY ORDER DATA
export const MY_ORDER_DATA = gql`
  query getAllOrders($currentPage: Int!, $pageSize: Int!) {
    customer {
      orders(
        sort: { sort_direction: DESC, sort_field: CREATED_AT }
        currentPage: $currentPage
        pageSize: $pageSize
      ) {
        total_count
        items {
          offline_credit
          offline_credit_details {
            status
          }
          is_topup
          number
          order_date
          number
          id
          uc_shippments {
            can_review
            items {
              item_sku
              item_id
              quantity
              item_status
              item_updated_at
              abusive_flag
              abusive_message
              review {
                average_rating
                summary
                text
                ratings_breakdown {
                  name
                  value
                }
              }
            }
          }
          payment_methods {
            name
            type
          }
          shipping_method
          shipments {
            tracking {
              title
              number
              carrier
            }
            id
            number
            items {
              product_sale_price {
                currency
                value
              }
            }
          }
          items {
            item_status
            item_updated_at
            product_sku
            product_child_sku
            id
            brand_name
            brand_label
            image_url
            order_status_cancel
            product_name
            product_url_key
            product_type
            discounts {
              amount {
                currency
                value
              }
              label
            }
            product_sale_price {
              currency
              value
            }
            selected_options {
              label
              value
              value_label
              swatch_data {
                type
                value
              }
            }
          }

          id
          items {
            quantity_ordered
            product_name
            product_type
            status
            selected_options {
              label
              value
            }
          }
          number
          order_date
          shipping_address {
            city
            company
            firstname
            lastname
            middlename
            postcode
            region
            country_code
            region_id
            street
            telephone
          }
          status
          total {
            total_shipping {
              currency
              value
            }
            grand_total {
              currency
              value
            }
            subtotal {
              currency
              value
            }
            base_grand_total {
              currency
              value
            }
            discounts {
              amount {
                currency
                value
              }
              label
            }
            milestone_reward {
              label
              code
              target_amount
              redeemed_discount
              actual_discount_amount
            }
          }
        }
      }
    }
  }
`;

export const GET_CUSTOMER_ORDERS = gql`
  query {
    rewards {
      balance
    }
    customer {
      firstname
      lastname
      email

      orders {
        total_count
        items {
          number
          id
          invoices {
            id
            items {
              id
              product_name
              product_sku
              quantity_invoiced
            }
          }
          payment_methods {
            name
            type
          }
          shipping_method
          shipments {
            tracking {
              title
              number
              carrier
            }
            id
            number
            items {
              product_sale_price {
                currency
                value
              }
            }
          }
          items {
            id
            brand_name
            image_url
            order_status_cancel

            product_name
            product_url_key
            product_type
            discounts {
              amount {
                currency
                value
              }
              label
            }
            product_sale_price {
              currency
              value
            }
            product_original_price {
              currency
              value
            }
            selected_options {
              label
              value
              value_label
              swatch_data {
                type
                value
              }
            }
          }

          id
          items {
            quantity_ordered
            product_name
            product_type
            status
            selected_options {
              label
              value
            }
          }
          number
          order_date
          shipping_address {
            city
            company
            firstname
            lastname
            middlename
            postcode
            region
            country_code
            region_id
            street
            telephone
          }
          status
          total {
            total_shipping {
              currency
              value
            }
            grand_total {
              currency
              value
            }
            subtotal {
              currency
              value
            }
            base_grand_total {
              currency
              value
            }
            discounts {
              amount {
                currency
                value
              }
              label
            }
          }
        }
      }
    }
  }
`;

export const GET_ORDER_DETAILS = gql`
  query GetCustomerOrders($orderNumber: String) {
    customer {
      firstname
      lastname
      email
      orders(
        filter: { number: { match: $orderNumber } }
        sort: { sort_direction: DESC, sort_field: CREATED_AT }
      ) {
        items {
        ${
          IsB2BLogged()
            ? `
          offline_credit_details {
            status
            items {
              items_detail {
                brand_label
                name
                image {
                  url
                }
              }
              item_sku
              original_qty
              original_price
            }
            grand_total
            bag_discount
            promo_discount
          }`
            : ``
        }
          is_topup
          qrlink
          emailcc
          is_virtual
          participant_details
          id
          billing_address {
            telephone
            region_id
            region
            country_code
            street
            telephone
            postcode
            city
            firstname
            lastname
          }
          ${IsB2BEnable() ? `total_gst_input_credit_earned` : ``}
          uc_shippments {
            shipment_id
            shipment_amount
            quid_pending_due
            invoice_code
            items {
              item_sku
              quantity
              item_status
              item_updated_at
              facility_code
              ${IsB2BEnable() ? `gst_input_credit_earned` : ``}
            }
            tracking_status {
              status
              date
              tracking_url
            }
          }
          cancel_order
          payment_methods {
            name
            type
          }
          shipping_method
          number
          items {
            product_sku
            product_child_sku
            status
            id
            image_url
            brand_name
            brand_label
            quantity_ordered
            product_name
            product_url_key
            product_type
            item_updated_at
            item_status
            product_sale_price {
              currency
              value
            }
            product_original_price {
              currency
              value
            }
            discounts {
              amount {
                currency
                value
              }
              label
            }
            selected_options {
              label
              value
              value_label
              swatch_data {
                type
                value
              }
            }
            gift_wrapping {
              design
              image {
                label
                url
              }
              uid
              price {
                currency
                value
              }
            }
          }
          number
          order_date
          shipping_address {
            region_id
            region
            country_code
            street
            telephone
            postcode
            city
            firstname
            lastname
          }
          status
          gift_wrapping {
            design
            image {
              label
              url
            }
            uid
            price {
              currency
              value
            }
          }
          total {
            loyalty_discount {
              discount
              discount_without_loyalty
            }
            grand_total {
              currency
              value
            }
            subtotal {
              currency
              value
            }
            total_shipping {
              currency
              value
            }
            discounts {
              amount {
                currency
                value
              }
              label
            }
            gb_wallet_spent {
              currency
              value
            }
            store_credit_spent {
              currency
              value
            }
            ${
              IsB2BLogged()
                ? `
              milestone_reward {
                redeemed_discount
              }
              `
                : ``
            }
          }
        }
      }
    }
  }
`;

export const CANCEL_ORDER = gql`
  query cancelOrder($ordId: String!, $mode: RefundType!) {
    cancelorder(order_id: $ordId, refund_method: $mode) {
      message
      status
    }
  }
`;

export const EMAIL_INVOICE = gql`
  mutation downloadInvoice($facility_code: String!, $order_id: String!, $invoice_code: String!) {
    downloadInvoice(
      facility_code: $facility_code
      order_id: $order_id
      invoice_code: $invoice_code
    ) {
      status
    }
  }
`;

export const GET_ORDER_ADDRESS = gql`
  query GetCustomerOrders($orderNumber: String) {
    customer {
      orders(
        filter: { number: { match: $orderNumber } }
        sort: { sort_direction: DESC, sort_field: CREATED_AT }
      ) {
        items {
          shipping_address {
            region_id
            region
            country_code
            street
            telephone
            postcode
            city
            firstname
            lastname
          }
        }
      }
    }
  }
`;

export const GET_REVIEW_METADATA = gql`
  {
    productReviewRatingsMetadata {
      items {
        id
        name
        values {
          value
          value_id
        }
      }
    }
  }
`;

export const CREATE_REVIEW = gql`
  mutation createProductReview(
    $name: String!
    $id: String!
    $valueId: String!
    $sku: String!
    $summary: String!
    $text: String!
    $itemId: String
  ) {
    createProductReview(
      input: {
        nickname: $name
        ratings: [{ id: $id, value_id: $valueId }]
        sku: $sku
        summary: $summary
        text: $text
        itemId: $itemId
      }
    ) {
      review {
        average_rating
        created_at
        nickname
        ratings_breakdown {
          name
          value
        }
        response
        status
        summary
        text
      }
    }
  }
`;
